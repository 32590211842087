<template>
  <div>
    <HoleModal ref="hole-modal" v-if="match"  :activeData="activeData" :match="match" v-on:matchUpdate="matchUpdate" v-on:navigateToShots="navigateToShots" />
    <TeamEditModal ref="team-edit-modal" v-if="match" :match="match" v-on:teamsUpdated="teamsUpdated" />
    <b-card class="small-padding mb-2  " >
      <b-row align-h="center" no-gutters>
        <b-col sm="12" :key="counter" >
          <b-table class="scorecard-table" style="width:100%" :items="items" :fields="fields" responsive sticky-header="400px">
              <template v-slot:cell()="data" >
                <div v-if="data.field.variant === 'number'">
                  <p class="center-text" v-if="!isEditing(data)" @click="setToEdit(data)">{{data.value}}</p>
                  <b-form inline v-if="isEditing(data)">
                    <b-form-select v-model="data.value" :options="siOptions" v-on:change="save(data)"></b-form-select>
                  </b-form>
                </div>

                <div v-if="data.field.variant === 'score'" @click="showModal(data)">
                  <p class="center-text"  >{{data.value? data.value: "-"}}</p>
                  <p v-if="data.item.hole !== 'Total' && !isNaN(getAverage(data))" class="average-text">Ave: {{getAverage(data)}}</p>
                </div>

                <div class="clickable" v-if="data.field.variant === 'hole'" @click="showModal(data)">
                  <p class="center-text">{{data.value}}</p>
                </div>

                <div class="clickable" v-if="data.field.variant === 'par'" @click="showModal(data)">
                  <p class="center-text">{{data.value}}</p>
                  <p v-if="data && data.item && data.item.si && data.item.si !== '-'" class="average-text">SI {{data.item.si}}</p>
                </div>
              </template>
              <template #head()="data">
                <div @click="showTeamModal(data)">
                  <span >{{ data.label}}</span>
                </div>
              </template>

          </b-table>
        </b-col>
      </b-row>
  </b-card>
  </div>
</template>
<script>
import Match from '../models/Match'
import HoleModal from './HoleModal'
import ScoreCardTable from '../models/ScoreCardTable'
import TeamEditModal from './TeamEditModal'
export default {
  name: 'ScoreCard',
  components: {
    HoleModal, TeamEditModal
  },
  props: ['reloadCounter', 'loadedMatch', 'scoreType'],
  data() {
    return {
      match: undefined,
      fields: [
        {key: 'hole', label: "#", variant: 'hole'},
        {key: 'par', label: "Par", variant: 'par'}
      ],
      items : [],
      editing: undefined,
      counter: 0,
      activeData: undefined,
      tableData: undefined,
      siOptions: [
      ]
    }
  },
  async mounted () {
    await this.loadMatch(this.loadedMatch)
    this.generateTableData()
    this.generateSIOptions()
    if (!this.match.tee) this.showMatchEditModal()
  },
  watch: {
    scoreType: function() {
      this.generateTableData()
      this.generateSIOptions()
    }
  },
  methods: {

    getAverage(data) {
      if (data.item.hole >= 1 && data.item.hole <= 18) {
        this.match.checkScoresExist()
        var playerScores = this.match.players.filter(player => player.key ===  data.field.key)[0].scores
        var thisHole = playerScores.filter(score => score.hole === data.item.hole)[0]
        if (thisHole && thisHole.average) return thisHole.average.toFixed(1)
      }
    },
    navigateToShots() {
      this.$emit('navigateToShots')
    },
    generateSIOptions() {
      for (var i = 0 ; i < this.items.length; i++) {
        this.siOptions.push({
          value: i+1, text: i+1
        })
      }
    },
    async loadMatch(loadedMatch) {
      if (this.loadedMatch) {
        this.match = this.loadedMatch
      }
      await this.match.addAverages()
      //this.createItems()
    },
    generateTableData() {
      this.tableData = new ScoreCardTable(this.loadedMatch, this.scoreType)
      this.items = this.tableData.items
      this.fields = this.tableData.fields
    },
    showModal(data) {
      if (data.item.hole !== "Total") {
        if (data.field.variant === "score") {
          data.value = this.getScore(data)
        }
        this.activeData = data
        this.$bvModal.show('putt-entry-modal')
      }
    },
    showMatchEditModal() {
      this.$bvModal.show('match-edit-modal')
    },
    isEditing(data) {
      return this.editing && data.field.key === this.editing.field && data.index === this.editing.index
    },
    setToEdit(data) {
      this.editing = {
        field: data.field.key,
        index: data.index,
        value: data.value
      }
    },
    async save(data) {
      this.items[data.index][this.editing.field] = data.value
      this.editing = undefined
      this.match.course.scoreCard = this.items.filter(item => {
        return item.hole !== "Total"
      })
      this.match.save()
      this.$emit('matchUpdate', this.match)
    },
    matchUpdate(match) {
      this.match = match
      this.generateTableData()
      this.$emit('matchUpdate', match)
    },
    teamsUpdated() {
      this.$bvModal.hide('team-edit-modal')
      this.match.save()
      this.generateTableData()
    },
    addHole() {
      this.items.push({
        hole: this.items.sort((a,b) => b.hole - a.hole)[0].hole + 1,
        par: 0,
        si: 0
      })
      this.items.sort((a,b) => a.hole - b.hole)
      this.match.course.scoreCard = this.items
      this.match.save()
      this.generateSIOptions()
    },
    getTDClass(value, key, item) {

      if (item.hole === "Total") return 'total'
      if (item.hole === "Front" || item.hole === "Back") {
        return 'frontAndBack'
      }
      if (key === 'hole') return 'hole-cell'
      if (key === 'par') return 'par-cell'
      if (this.scoreType === "matchPlay") {
        return value.indexOf("Up") > -1 ? "bogey" : value === "A/S"? "par" : ""
      }else{
        if (this.scoreType === "stableford") {
          return 'stableford' + value
        }else {
          return this.tableData.getClass(value, key, item, this.match) + " clickable score-cell"
        }
      }
    },
    showTeamModal(value, key, item) {
      this.$bvModal.show('team-edit-modal')
    },
    getScore(data) {
      var scores = this.match.players.filter(player => player.key === data.field.key)[0].scores
      if (scores) {
        var thisHoleScore = scores.filter(hole => hole.hole === (data.index + 1))[0]
        if (thisHoleScore) return thisHoleScore.shots
      }
      return "-"
    },

    getClass(data) {
      var score = this.getScore(data)
      if (score === 0) return "unscored"
      var hole = this.match.course.scoreCard[data.index]
      var player = this.match.players.filter(player => player.key === data.field.key)[0]
      var strokes = strokesWithHandicap(player.handicap, score, hole.si, this.match.course.scoreCard.length)
      var scoreToPar = strokes - hole.par
      if (scoreToPar < 0) return 'birdie'
      if (scoreToPar === 1) return 'bogey'
      if (scoreToPar > 1) return 'doubleBogey'
      return 'par'
    }
  }
}

function getShots(handicap, strokeIndex, holes) {
  return Math.floor(((handicap - strokeIndex) / holes) + 1)
}

function strokesWithHandicap(handicap, strokes, strokeIndex, holes) {
  if (1 * strokes === 0) return 0
  var shots = getShots(handicap, strokeIndex, holes)
  /*
  console.log("Handicap: " + handicap)
  console.log("Strokes: " + strokes)
  console.log("SI: " + strokeIndex)
  console.log("Shots: " + shots)
  console.log("Gross Score: " + (strokes - shots))
  */
  return strokes - shots
}

</script>

<style >
.number-entry {
  width: 80px
}

.birdie {
  background-color: #0F9D58;
  font-weight: bold;
  color: white;
}

.bogey {
  background-color:	#F4B400;
  font-weight: bold;
  color: white;
}

.doubleBogey {
  background-color:	#DB4437;
  font-weight: bold;
  color: white;
}

.par {
  background-color:	white;
  color: #4a4a4a;
  font-weight: bold;
  vertical-align: center !important
}

.total {
  background-color: #e8e8e8;
  font-weight: bold
}

.frontAndBack {
  background-color: #e8e8e8;
  font-size: 14px;
}

.unscored {
  background-color:	white;
  color: #4a4a4a;
  font-weight: bold;
}


.full-cell-button, .full-cell-button:focus{
  width: 100%;
  height: 100%;
  background-color: #00000000;
  border-color: #00000000;
  font-weight: bold;
  box-shadow: none;
}

.full-cell-button:hover{
  color: #4a4a4a;
  width: 100%;
  height: 100%;
  background-color: #00000000;
  border-color: #00000000;
  font-weight: bold;
}

.full-cell-button:focus{
  color: grey;
  width: 100%;
  height: 100%;
  background-color: #00000000;
  border-color: #00000000;
  font-weight: bold;
}

.center-text {
  align-items: center;
  margin:0;
}

.table td {
  vertical-align: middle;
}

.score-cell {
  width: 25%;
  padding:0px !important;
}

.hole-cell {
  background-color: #e8e8e8;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.par-cell {
  font-weight: bold
}

.table thead th, .table tbody td {
  border-bottom: none;
  border-top: none;
}
/*
.table thead th {
  background-color: white

}
*/

.table thead tr {
  border-color: red
}

.scorecard-table th {
  font-size: 12px;
  background-color: white
}

.stableford0, .stableford- {
  background-color:	#f0f0f0;
  color: #4a4a4a;
  font-weight: bold;
}

.stableford1 {
  background-color:	#415e94;
  color: white;
  font-weight: bold;
}

.stableford2 {
  background-color:	#4dc36b;
  color: white;
  font-weight: bold;
}

.stableford3 {
  background-color:	#f0b400;
  color: white;
  font-weight: bold;
}

.stableford4 {
  background-color:	#d5546e;
  color: white;
  font-weight: bold;
}

.stableford5, .stableford6, .stableford7 {
  background-color:	#4b18ab;
  color: white;
  font-weight: bold;
}

.average-text {
  font-size:8px;
  margin: 0px
}

.table-number {
  white-space: nowrap;
}

.teamA {
  background-color: #144d90 !important;
  color: white;
}

.teamB {
  background-color: #b11c21 !important;
  color: white;
}
</style>
