import Vue from 'vue'
import { LoaderPlugin } from 'vue-google-login';
import * as LoginClient from '../clients/LoginClient'
Vue.use(LoaderPlugin, {
  client_id: "726629554189-9q49g6o2lqd2mtvgldmprqofnune34o6.apps.googleusercontent.com"
});

export default class CurrentUser {
  constructor() {

  }
  async load() {
    var auth2 = await Vue.GoogleAuth
    var user = auth2.currentUser.get()
    this.firstName = user.getBasicProfile().getGivenName()
    this.surname = user.getBasicProfile().getFamilyName()
    this.image = user.getBasicProfile().getImageUrl()
    this.id = user.getBasicProfile().getId()
    var savedUser = await LoginClient.login() //should add caching
    this.key = savedUser.uuid
  }
}
