import * as CoursesClient from '../clients/CoursesClient'
export default class Course {
  constructor(holes) {
    this.scoreCard = [

    ]
    if (!holes) holes = 18
    for (var i = 1; i <= holes; i++) {
      this.scoreCard.push({hole: i})
    }
  }
  async save() {
    this.scoreCard.forEach(hole => {
      if (hole.latLong) {
        hole.locations = {
          green : {
            lat: hole.latLong.split(",")[0].trim(),
            long: hole.latLong.split(",")[1].trim()
          }
        }
        delete hole.latLong
      }
    })

    await CoursesClient.saveCourse(this)
  }
  addTee(colour, rating, slope) {
    if (!this.tees) this.tees = {}
    this.tees[colour] = {
      rating: rating,
      slope: slope
    }
  }
}
