<template>
  <div>
    <VueApexChart  v-if="series" height="200" :options="options" :series="series"></VueApexChart>
  </div>
</template>
<script>

import VueApexChart from 'vue-apexcharts'
import ShotDataByDate from '../../models/ShotDataByDate'
import moment from 'moment'
export default {
  name: 'HandicapTimeSeriesChart',
  components: {
    VueApexChart
  },
  props: ['data'],
  data() {
    return {
      series: undefined,
      options: undefined
    }
  },
  mounted () {
    this.createData()
  },
  methods: {
    createData() {
      var sortedDifferentials = [...this.data].sort((a,b) => a.differential - b.differential)
      console.log(sortedDifferentials)
      var handicapCalculationRules = handicapCalculation[sortedDifferentials.length]
      var countingDifferentials = sortedDifferentials.slice(0,handicapCalculationRules.bestRoundsToUse)
      var nonCountingDifferentials = sortedDifferentials.slice(handicapCalculationRules.bestRoundsToUse,20)
      var differentialsSortedByDate = [...this.data].sort((a,b) => new Date(a.date) - new Date(b.date))
      this.series = [
        {
          name: "Counting",
          data: countingDifferentials.map(obj => [findIndexForDifferential(obj, differentialsSortedByDate), obj.differential])
        },
        {
          name: "Non-Counting",
          data: nonCountingDifferentials.map(obj => [findIndexForDifferential(obj, differentialsSortedByDate), obj.differential])
        }
      ]
      this.options = {
        dataLabels:{
          enabled: false
        },
        tooltip : {
          x: {
           formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
             return differentialsSortedByDate[value].date + " " + differentialsSortedByDate[value].location + ` (${differentialsSortedByDate[value].totalScore})`
            }
          }
        },
        chart: {
          type: 'scatter',

          dropShadow: {
            enabled: true,
            color: '#000',
            top: 9,
            left:4,
            blur: 10,
            opacity: 0.2
          },

          toolbar: {
            show: false
          }
        },
        colors: ['#00e396','#ff4560'],
        xaxis: {
          type: 'category',
          labels: {
            rotate: 0,
            style: {
              fontSize: '10px'
            }
          },
          overwriteCategories: getDateList(countingDifferentials.concat(nonCountingDifferentials)),

        },
        yaxis: {
          min: 0,
          max: Math.max(36,sortedDifferentials[sortedDifferentials.length - 1].differential),
          labels: {
            formatter: function(val, index) {
              return val.toFixed(1);
            }
          }
        }
      }
    }
  }
}
var handicapCalculation = {
  1: {bestRoundsToUse: 0, adjustment: 0},
  2: {bestRoundsToUse: 0, adjustment: 0},
  3: {bestRoundsToUse: 1, adjustment: 2},
  4: {bestRoundsToUse: 1, adjustment: 1},
  5: {bestRoundsToUse: 1, adjustment: 0},
  6: {bestRoundsToUse: 2, adjustment: 1},
  7: {bestRoundsToUse: 2, adjustment: 0},
  8: {bestRoundsToUse: 2, adjustment: 0},
  9: {bestRoundsToUse: 3, adjustment: 0},
  10: {bestRoundsToUse: 3, adjustment: 0},
  11: {bestRoundsToUse: 3, adjustment: 0},
  12: {bestRoundsToUse: 4, adjustment: 0},
  13: {bestRoundsToUse: 4, adjustment: 0},
  14: {bestRoundsToUse: 4, adjustment: 0},
  15: {bestRoundsToUse: 5, adjustment: 0},
  16: {bestRoundsToUse: 5, adjustment: 0},
  17: {bestRoundsToUse: 6, adjustment: 0},
  18: {bestRoundsToUse: 6, adjustment: 0},
  19: {bestRoundsToUse: 7, adjustment: 0},
  20: {bestRoundsToUse: 8, adjustment: 0}
}
function getDateList(data) {
  return data.sort((a,b) => new Date(a.date) - new Date(b.date)).map(obj => moment(obj.date).format("YYYY-MM-DD"))
}

function findIndexForDifferential(differential, allDifferentials) {
  for (var i = 0; i < allDifferentials.length; i++) {
    if (allDifferentials[i].differential === differential.differential && allDifferentials[i].date === differential.date) return i
  }

}

</script>
