<template>
  <b-modal hide-header hide-footer centered id="match-edit-modal" >
    <b-container>
      <b-row align-h="end" class="mb-3">
        <b-button  class="close" @click="close">×</b-button>
      </b-row>
      <b-form>
        <div>
          <label class="form-label">{{match.date}}: {{match.course.location}}</label>
        </div>
        <label class="form-label" for="tee-select">Tee</label>
        <VueSelect placeholder="Select tee" class="mt-2" :options="teeOptions" v-model="tee" @input="teeSelected"/>
      </b-form>
    </b-container>
  </b-modal>
</template>

<script>
import VueSelect from 'vue-select'
import * as CoursesClient from '../clients/CoursesClient'
export default {
  name: 'MatchEditModal',
  components: {
    VueSelect
  },
  props: ['match'],
  data() {
    return {
      tee: undefined,
      teeOptions: undefined,
      error: true,
      errorText: undefined
    }
  },
  mounted () {
    if (this.match.tee) this.tee = this.match.tee
    this.getTeeDropdownList()
  },
  watch: {

  },
  methods: {
    async getTeeDropdownList() {
      if (this.match.course && this.match.course.tees) {
        this.teeOptions = Object.keys(this.match.course.tees)
      }else {
        var courses = await CoursesClient.getCourses()
        var thisCourse = courses.filter(course => course.id === this.match.course.id)[0]
        this.match.course.tees = thisCourse.tees
        this.teeOptions = Object.keys(thisCourse.tees)
      }
    },
    async teeSelected(tee) {
      this.match.tee = tee
      await this.match.save()
      this.$emit('matchUpdate', this.match)
      this.close()
    },
    close() {
      this.$bvModal.hide('match-edit-modal')
    }
  }
}
</script>

<style>

.error-text {
  color: red;
  font-size: 10pt
}
</style>
