<template>
  <div>
    <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.match-expand variant="light" class='p-0'>
          <h5 class="mb-1 mt-1"> {{match.date}} {{match.course.location}}: {{match.course.courseName}}
             <i class="material-icons nav-icon when-open" style="color: grey; font-size:12px">{{expandIcon}}</i>
           </h5>
        </b-button>
      </b-card-header>
      <b-collapse id="match-expand" accordion="match-expand">
        <b-card-body class="pb-1 pt-2 text-12">
          <b-row class="clickable" @click="showMatchEditModal()">
            <b-col cols="4">
              Tees: {{tee}}
            </b-col>
            <b-col cols="5">
              Course Rating: {{(match.getCourseRating() * 1).toFixed(1)}}
            </b-col>
            <b-col cols="3">
              Slope: {{(1 * match.getSlope()).toFixed(0)}}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
                <i class=" small-icon material-icons grey-text clickable mt-2" @click="openFullScorecard()">open_in_full</i>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
  </div>
</template>
<script>
export default {
  name: 'MatchDetail',
  props: ['match', 'reloadCounter'],
  components: {
  },
  data() {
    return {
      tee: undefined,
      expanded: false,
      expandIcon: "expand_more"
    }
  },
  async mounted () {
    this.tee = this.match.tee
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (isJustShown) {
        this.expandIcon = "expand_less"
      }else {
        this.expandIcon = "expand_more"
      }
    })
  },
  watch: {
    reloadCounter() {
      this.tee = this.match.tee
    }
  },
  methods: {
    showMatchEditModal() {
      this.$bvModal.show('match-edit-modal')
    },
    openFullScorecard() {
      this.$router.push({ name: 'ScoreCardFullScreen', params: { cardId: this.match.uuid } })
    }
  }
}

</script>

<style>
.grey-text {
  color: #363636
}

.small-icon {
  font-size: 18px !important;
}

</style>
