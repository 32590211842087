import * as Scorer from './Scorer'
import Golfer from './Golfer'
export default class ScoreCardTable {
  constructor (match, scoreType) {
    if (!scoreType) scoreType = 'strokePlay'
    this.items = []
    this.fields = [
      {key: 'hole', label: "#", tdClass: 'getTDClass', variant: "hole"},
      {key: 'par', label: "Par", variant: 'par', tdClass: 'getTDClass'}
    ]
    match.players.forEach(player => {
      if (match.teams) {
        var team = match.teams.teamA.players.filter(key => key === player.key).length === 1? 'teamA': 'teamB'
      }
      this.fields.push ({
        key: player.key, label: player.firstName, variant: 'score', tdClass: 'getTDClass', thClass: team + " clickable"
      })
    })
    match.course.scoreCard.forEach (hole => {
      var item = {hole: hole.hole, par: hole.par, si: hole.si}
      match.players.forEach(player => {
        if (player.scores) {
          var holeForPlayer = player.scores.filter(score => score.hole === hole.hole)[0]
          if (holeForPlayer && holeForPlayer.shots) {
            if (scoreType === "matchPlay") {
              var matchPlayScore
              if (match.players.length === 2) {
                var otherPlayer = match.players.filter(p => p.key !== player.key)[0]
                matchPlayScore = Scorer.matchPlayScore(player, otherPlayer, match.course.scoreCard, holeForPlayer.hole)
              }else {
                matchPlayScore = Scorer.teamMatchPlayScore(player.key, match, holeForPlayer.hole)
              }
              item[player.key] = matchPlayScore > 0? matchPlayScore + "Up": matchPlayScore < 0? "": "A/S"
            }else {
              if (scoreType === "stableford") {
                item[player.key] = Scorer.stableFordScore(item.par, holeForPlayer.shots, player.getPlayingHandicap(), item.si, match.course.scoreCard.length)
              }else {
                item[player.key] = holeForPlayer.shots
              }
            }
          }
        }
      })
      this.items.push(item)
    })
    //Add totals
      var totals = {
        hole: "Total",
        par: this.items.reduce((t, n) => t + (n.par || 0), 0),
        si: "-"
      }
      if (this.items.length === 18) {
        var front = {
          hole: "Front",
          par: [...this.items].splice(0,9).reduce((t, n) => t + (n.par || 0), 0),
          si: "-"
        }

        var back = {
          hole: "Back",
          par: [...this.items].splice(9,9).reduce((t, n) => t + (n.par || 0), 0),
          si: "-"
        }
      }

    if (scoreType === "strokePlay" || scoreType === "stableford") {
      match.players.forEach(player => {
        if (player.scores) {
          if (scoreType === "strokePlay" || scoreType === "stableford") {
            totals[player.key] = this.items.reduce((t, n) => t + (!isNaN(n[player.key])? n[player.key]: 0), 0)
            if (front) front[player.key] = [...this.items].splice(0,9).reduce((t, n) => t + (!isNaN(n[player.key])? n[player.key]: 0), 0)
            if (back) back[player.key] = [...this.items].splice(9,9).reduce((t, n) => t + (!isNaN(n[player.key])? n[player.key]: 0), 0)
          }
          //if (scoreType === "strokePlay")
        }
      })
      this.items.push(totals)
      if (front) this.items.push(front)
      if (back) this.items.push(back)
    }
  }


  getClass(score, key, item, match) {
    if (!isNaN(item.hole)) {
      if (score === 0 || !score) return "unscored"
      var hole = match.course.scoreCard.filter(scoreCardHole => scoreCardHole.hole === item.hole)[0]
      var player = match.players.filter(player => player.key === key)[0]
      var strokes = strokesWithHandicap(player.getPlayingHandicap(), score, hole.si, match.course.scoreCard.length)
      var scoreToPar = strokes - hole.par
      if (scoreToPar < 0) return 'birdie'
      if (scoreToPar === 1) return 'bogey'
      if (scoreToPar > 1) return 'doubleBogey'
      return 'par'
    }else {
      return 'total'
    }
  }
}

function getShots(handicap, strokeIndex, holes) {
  return Math.floor(((handicap - strokeIndex) / holes) + 1)
}

function strokesWithHandicap(handicap, strokes, strokeIndex, holes) {
  if (1 * strokes === 0) return 0
  var shots = getShots(handicap, strokeIndex, holes)
  /*
  console.log("Handicap: " + handicap)
  console.log("Strokes: " + strokes)
  console.log("SI: " + strokeIndex)
  console.log("Shots: " + shots)
  console.log("Gross Score: " + (strokes - shots))
  */
  return strokes - shots
}
