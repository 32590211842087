<template>
  <div>
    <b-button-group class="mt-2" v-if="!course">
      <b-button @click="newCourse(9)">9</b-button>
      <b-button @click="newCourse(18)">18</b-button>
    </b-button-group>
    <VueSelect placeholder="Select location" class="mt-2" v-if="coursesLoaded" :options="courses.locations()" v-model="selectedLocation" @input="locationSelected" />
    <VueSelect placeholder="Select course" class="mt-2" v-if="courseNames && selectedCourseName !== selectedLocation" :options="courseNames" v-model="selectedCourseName"  @input="courseSelected"/>

    <div v-if="course">
      <b-form inline>
        <b-form-input class="mt-2 mr-2" v-model="course.location" placeholder="location" />
        <b-form-input class="mt-2" v-model="course.courseName" placeholder="courseName" />
      </b-form>
      <b-form v-for="(tee, i) in tees" inline v-bind:key="'tee' + i">
        <b-form-input class="mt-2 mr-2" v-model="tee.colour" placeholder="Colour" />
        <b-form-input type="number" class="mt-2" v-model="tee.rating" placeholder="rating" />
        <b-form-input type="number" class="mt-2" v-model="tee.slope" placeholder="slope" />
      </b-form>
      <b-button @click="submit"><p v-if="!loading">Submit</p>
        <b-spinner v-if="loading" variant="success" type="grow" label="Spinning"></b-spinner>
      </b-button>
      <b-form inline v-for="hole in course.scoreCard" v-bind:key="hole.hole">
        <h6 class="mr-2"> {{hole.hole}}</h6>
        <b-form-group label="par">
          <b-form-select class="mr-2" v-model="course.scoreCard[hole.hole - 1].par" :options="[3,4,5]"></b-form-select>
        </b-form-group>
        <b-form-group label="si">
          <b-form-select class="mr-2" v-model="course.scoreCard[hole.hole - 1].si" :options="siOptions()"></b-form-select>
        </b-form-group>
        <b-form-group label="lat long">
          <b-form-input class="mr-2" v-model="course.scoreCard[hole.hole - 1].latLong" placeholder="Coordinates"></b-form-input>
        </b-form-group>
      </b-form>
      <b-button @click="submit"><p v-if="!loading">Submit</p>
        <b-spinner v-if="loading" variant="success" type="grow" label="Spinning"></b-spinner>
      </b-button>

    </div>
  </div>
</template>

<script>
import Course from '../models/Course'
import CourseList from '../models/CourseList'
import VueSelect from 'vue-select'
export default {
  components: {
    VueSelect
  },
  props: [],
  data() {
    return {
      course: undefined,
      loading: false,
      courses: undefined,
      coursesLoaded: false,
      courseNames: undefined,
      selectedCourseName: undefined,
      selectedLocation: undefined,
      tees: [
        {colour: undefined},{colour: undefined},{colour: undefined},{colour: undefined}
      ]
    }
  },
  mounted () {
    this.loadCourses()
  },
  methods: {
    newCourse(holes) {
      this.course = new Course(holes)
    },
    siOptions() {
      var arr = []
      for (var i = 1; i <= this.course.scoreCard.length; i++) {
        arr.push(i)
      }
      return arr
    },
    async submit() {
      this.course.tees = {}
      for (var tee of this.tees) {
        if (tee.rating && tee.slope) {
          this.course.tees[tee.colour] = {
            rating: tee.rating,
            slope: tee.slope
          }
        }
      }
      console.log(this.course.tees)
      this.loading = true
      await this.course.save()
      this.loading = false
      this.course.scoreCard.forEach(hole => {
        hole.latLong = hole.locations.green.lat + "," + hole.locations.green.long
      })
      await this.loadCourses()
    },
    async loadCourses() {
      this.coursesLoaded = false
      this.courses = new CourseList()
      await this.courses.load()
      this.coursesLoaded = true
    },
    locationSelected(location) {
      if (location) {
        this.selectedLocation = location
        this.courseNames = this.courses.courseNames(location)
        if (this.courseNames.length === 1) {
          this.courseNames[0]
          this.courseSelected(this.courseNames[0])
        }
      }else {
        this.courseNames = undefined
        this.selectedLocation = undefined
      }
    },
    courseSelected(courseName) {
      this.selectedCourseName = courseName
      this.course = new Course()
      var selectedCourse = this.courses.getCourse(this.selectedLocation, courseName)
      Object.keys(selectedCourse).forEach(key => this.course[key] = selectedCourse[key])
      this.course.scoreCard.forEach(hole => {
        hole.latLong = hole.locations.green.lat + "," + hole.locations.green.long
      })
      console.log(selectedCourse)
      if (selectedCourse.tees) {
        this.tees = Object.keys(selectedCourse.tees).map(key => {
          return {
            colour: key, slope: selectedCourse.tees[key].slope, rating: selectedCourse.tees[key].rating
          }
        })
      }else {
        this.tees = []
      }
      while (this.tees.length < 4) {
        this.tees.push({
          colour: undefined
        })
      }
    }
  }
}

</script>
