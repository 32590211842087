<template>
  <b-modal hide-header hide-footer centered id="player-edit-modal" >
    <b-form>
      <label class="form-label" for="handicap-entry">FIRST NAME</label>
      <b-form-input id="first_name_entry" class="mb-2" type="text" v-model="player.firstName"></b-form-input>
      <label class="form-label" for="handicap-entry">SURNAME</label>
      <b-form-input id="surname_entry" class="mb-2" type="text" v-model="player.surname"></b-form-input>
      <b-container>
        <b-row>
          <b-col cols="4" class="p-0 pr-2">
          <div>
            <label class="form-label" for="handicap-entry">H'CAP INDEX</label>
            <b-form-input id="handicap-entry" class="mb-2 handicap-entry" type="number" v-model="player.handicap" @input="playingHandicap = player.setPlayingHandicap(match)">Handicap</b-form-input>
          </div>
        </b-col>
        <b-col cols="4" class="p-0 pr-1 pl-1">
        <div>
          <label class="form-label" for="playing-handicap-entry">PLAYING H'CAP</label>
          <b-form-input id="playing-handicap-entry" class="mb-2 handicap-entry" disabled type="number" v-model="playingHandicap">Handicap</b-form-input>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 pl-2">
      <div v-if="matchHandicap !== undefined">
        <label class="form-label" for="match-handicap-entry">MATCH HCAP</label>
        <b-form-input id="match-handicap-entry" disabled class="mb-2 handicap-entry" type="number" v-model="matchHandicap">Handicap</b-form-input>
      </div>
    </b-col>
        </b-row>
      </b-container>
      <b-button class="mt-2 button-primary" v-if="!player.key" @click="addPlayer">ADD</b-button>
      <b-button class="mt-2 button-primary" v-if="player.key" @click="updatePlayer">Update</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import Match from '../models/Match'
import { getDistance } from 'geolib';
export default {
  name: 'PlayerEditModal',
  components: {
  },
  props: ['player', 'match', 'reloadCounter'],
  data() {
    return {
      playingHandicap: this.player.playingHandicap,
      matchHandicap: this.player.matchHandicap
    }
  },
  mounted () {
    if (this.player) {
      this.player.setPlayingHandicap(this.match)
      this.playingHandicap = this.player.playingHandicap
      this.matchHandicap = this.player.matchHandicap
    }
  },
  watch: {
    reloadCounter() {
      this.player.setPlayingHandicap(this.match)
      this.playingHandicap = this.player.playingHandicap
      this.matchHandicap = this.player.matchHandicap
    },
    player() {
      this.player.setPlayingHandicap(this.match)
      this.playingHandicap = this.player.playingHandicap
      this.matchHandicap = this.player.matchHandicap
    }
  },
  methods: {
    updatePlayer() {
      this.player.playingHandicap = this.playingHandicap
      this.player.matchHandicap = this.matchHandicap
      this.$emit("playerUpdated", this.player)
    },
    addPlayer() {
      this.player.playingHandicap = this.playingHandicap
      this.player.matchHandicap = this.matchHandicap
      this.$emit("playerAdded", this.player)
    }
  }
}
</script>

<style>

.shot-count-select-button{
  height: 50px;
  width: 50px;
}

.margin-row {
  margin-bottom: 20px
}

.btn-active {
  border-color: #4a4a4a;
}

.btn-secondary {
  background-color:#4a4a4a;
  border-color: #4a4a4a;
}

.btn:focus {
  box-shadow: none
}

.small-grey-text {
  font-size: 12px;
  text-align: center;
  color:  #5e5e5e
}

.no-margin {
  margin:0;
  text-align: center
}

.distance-badge {
  text-align: center;
  font-family: "Lucida Console", Courier, monospace;
  font-weight: normal;
}

.handicap-entry {
  max-width: 100%;
}
</style>
