import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import FrontPage from '@/components/FrontPage'
import ScoreCardFullScreen from '@/components/ScoreCardFullScreen'
Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', name: 'Home', component: Home },
    {
      path: '/sgc',
      name: "FrontPage",
      component: FrontPage,
      meta: {
        title: 'sgc',
      }
    },
    {
      path: '/scorecard/:cardId',
      name: "ScoreCardFullScreen",
      component: ScoreCardFullScreen,
      meta: {
        title: 'Scorecard',
      }
    }
  ] })
