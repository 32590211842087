<template>
  <div class="mt-2">
    <MatchEditModal ref="match-edit-modal" v-if="match" :match="match" v-on:matchUpdate="matchUpdated" />
    <PlayerEditModal v-if="activePlayer" :match="match" :player="activePlayer" v-on:playerUpdated="playerUpdated"/>
    <b-row align-h="center">
      <b-col sm="12" md="6">
        <MatchDetail v-if="!matchLoading" :match="match" :reloadCounter="reloadCounter"/>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col sm="12" md="6">
        <MatchScoringPage v-if="!matchLoading" :match="match" :reloadCounter="reloadCounter" v-on:playerClicked="showPlayerUpdateModal" v-on:scoreTypeChanged="changeScoreType"/>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col sm="12" md="6">
        <ScoreCard v-if="!matchLoading" :scoreType="scoreType" :loadedMatch="match" v-on:matchUpdate="matchUpdated" v-on:navigateToShots="navigateToShots"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ScoreCard from './ScoreCard'
import MatchScoringPage from './MatchScoringPage'
import PlayerEditModal from './PlayerEditModal'
import MatchDetail from './MatchDetail'
import Match from '../models/Match'
import MatchEditModal from './MatchEditModal'

export default {
  name: 'ScoreCardPage',
  components: {
    ScoreCard,MatchScoringPage, PlayerEditModal, MatchDetail, MatchEditModal
  },
  props: ['matchId'],
  data() {
    return {
      matches: undefined,
      match: undefined,
      reloadCounter: 0,
      matchLoading: true,
      activePlayer: undefined,
      scoreType: 'strokePlay',
      user: undefined
    }
  },
  async mounted () {
    this.loadMatch()
  },
  watch: {
  },
  methods: {
    changeScoreType(scoreType) {
      this.scoreType = scoreType
    },
    navigateToShots() {
      this.$emit('navigateToShots')
    },
    showPlayerUpdateModal(player) {
      this.activePlayer = player
      this.$nextTick(function () {
        this.$bvModal.show('player-edit-modal')
    })
    },
    playerUpdated(updatedPlayer) {
      this.match.players.filter(player => player.key === updatedPlayer.key)[0] = updatedPlayer
      this.$bvModal.hide('player-edit-modal')
      this.reloadCounter++
    },
    async loadMatch() {
      this.matchLoading = true
      this.match = new Match()
      await this.match.load(this.matchId)
      this.matchLoading = false
      console.log(this.match)
      if (!this.match.uuid) {
        console.log("EMITTING")
      //Happens when the match has been deleted
        this.$emit('noMatch')
      }
    },
    matchUpdated(match) {
      this.match = match
      this.reloadCounter++
    },
  }
}

</script>

<style>
.align-left {
    text-align:left;
    color: #787878;

}

.match-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
}

.image-cropper {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

img {
  display: inline;
  margin: 0 auto;
  height: 50px;
  width: 50px;
}

</style>
