<template>
  <div class="black-background fullscreen">
    <b-container class="h-100" fluid>
      <b-row class="min-vh-100" align-h="center" >
        <b-col cols="12" lg="6">
          <div class="h-100 d-flex flex-column d-sm-none ">
            <b-row class="pt-5 justify-content-md-center" >
              <b-col cols="6">
                    <img class="nav-logo" src="@/assets/sgc_logo.png" />
              </b-col>
              <b-col cols="6">
                <b-row align-h="center">
                  <div v-if="userLoaded" class="image-cropper">
                    <img :src="user.image" />
                  </div>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="pt-3 justify-content-md-center" >
              <b-col cols="12">
                <b-row align-h="center">
                  <div v-if="match" class='white-text'>
                    {{getFormattedDate(match.date)}} @ {{getCourseName()}}
                  </div>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="flex-grow-1 justify-content-center secondary-black-background " align-h="center" >
              <b-col cols="5" class="h-100 pl-0 pr-4">
                  <b-row v-if="front9Scores" class="pt-2 pb-2 h-100">
                    <ScoreCardFullScreenTable :items="getScoresFor9('front')" />
                  </b-row>
              </b-col>
              <b-col cols="5" class="h-100 pr-0 pl-4" v-if="back9Scores && back9Scores.length > 2">
                  <b-row v-if="back9Scores" class="pt-2 pb-2 h-100">
                    <ScoreCardFullScreenTable :items="getScoresFor9('back')" />
                  </b-row>
              </b-col>
            </b-row>
            <b-row class="flex-grow-1 justify-content-center secondary-black-background d-none d-sm-flex" align-h="center" >
              <b-col cols="12" class="pl-4 pr-4">
                  <b-row v-if="front9Scores" class="">
                    <ScoreCardFullScreenTableHorizontal :items="getScoresFor9('front')" />
                  </b-row>
              </b-col>
              <b-col cols="12" class="pr-4 pl-4" v-if="back9Scores && back9Scores.length > 2">
                  <b-row v-if="back9Scores" class="">
                    <ScoreCardFullScreenTableHorizontal :items="getScoresFor9('back')" />
                  </b-row>
              </b-col>
            </b-row>
        </div>
        <div class="h-100 flex-column d-none d-sm-flex pt-4">
          <b-row class="h-100">
            <b-col cols="2" class="h-100">
              <b-row class="pt-5 justify-content-md-center mt-4" >
                <img class="nav-logo" src="@/assets/sgc_logo.png" />
              </b-row>
              <b-row align-h="center" class="pt-5">
                <div v-if="userLoaded" class="image-cropper">
                  <img :src="user.image" />
                </div>
              </b-row>
            </b-col>
            <b-col cols="10" class="h-100">
            <b-row class="pt-3 justify-content-md-center" >
              <b-col cols="12">
                <b-row align-h="center">
                  <div v-if="match" class='white-text'>
                    {{getFormattedDate(match.date)}} @ {{getCourseName()}}
                  </div>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="flex-grow-1 justify-content-center secondary-black-background mt-2 ml-2 mr-2" align-h="center" >
              <b-col cols="12" class="pl-4 pr-4">
                  <b-row v-if="front9Scores" class="">
                    <ScoreCardFullScreenTableHorizontal :items="getScoresFor9('front')" />
                  </b-row>
              </b-col>
              <b-col cols="12" class="pr-4 pl-4" v-if="back9Scores && back9Scores.length > 2">
                  <b-row v-if="back9Scores" class="">
                    <ScoreCardFullScreenTableHorizontal :items="getScoresFor9('back')" />
                  </b-row>
              </b-col>
            </b-row>
            <b-row align-h="end" class="pr-3 secondary-black-background mr-2 ml-2 pb-2" v-if="front9Scores">
              <b-col cols="auto">
                <div class="bold white-text">
                  Total:<span style="font-size:25px" class="ml-3">{{getTotalScore()}}</span>
                </div>
              </b-col>
            </b-row>
          </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    </b-container>
  </div>
</template>

<script>
import CurrentUser from '../models/CurrentUser'
import ScoreCardFullScreenTable from './ScoreCardFullScreenTable'
import ScoreCardFullScreenTableHorizontal from './ScoreCardFullScreenTableHorizontal'
import Match from '../models/Match'
import moment from 'moment'
export default {
  name: 'ScoreCardFullScreen',
  components: {
    ScoreCardFullScreenTable, ScoreCardFullScreenTableHorizontal
  },
  data() {
    return {
      user: undefined,
      userLoaded: false,
      front9Scores: undefined,
      back9Scores: undefined,
      front9card: undefined,
      back9card: undefined,
      match: undefined,
      fields: [
        {key: 'hole', tdClass: 'less-cell-padding', thClass: 'less-cell-padding'},
        {key: 'par', tdClass: 'less-cell-padding', thClass: 'less-cell-padding'},
        {key: 'strokes', tdClass: 'less-cell-padding', thClass: 'less-cell-padding'}
      ]
    }
  },
  methods: {
    getCourseName() {
      if (this.match.course.location === this.match.course.courseName) return this.match.course.location
      return `${this.match.course.location}: ${this.match.course.courseName}`
    },
    getScoresFor9(frontOrBack) {
      return scoresFor9(generateScorecard(this.match), frontOrBack)
    },
    getFormattedDate(date) {
      return moment(date).format('Do MMM YYYY')
    },
    getTotalScore() {
      console.log(generateScorecard(this.match).reduce((total, hole) => total + hole.strokes, 0))
      return generateScorecard(this.match).reduce((total, hole) => total + hole.strokes, 0)
    }

  },
  async mounted () {
    this.loaded = false
    this.user = new CurrentUser()
    await this.user.load()
    this.userLoaded=true
    var match = new Match()
    await match.load(this.$route.params.cardId)
    this.match = match
    this.front9Scores = scoresFor9(generateScorecard(match), 'front')
    this.back9Scores = scoresFor9(generateScorecard(match), 'back')
  },
  async activated () {
    this.loaded = false
    this.user = new CurrentUser()
    await this.user.load()
    this.userLoaded=true
    var match = new Match()
    await match.load(this.$route.params.cardId)
    this.match = match
    this.front9Scores = scoresFor9(generateScorecard(match), 'front')
    this.back9Scores = scoresFor9(generateScorecard(match), 'back')
  }
}

function generateScorecard(match) {
  var player = match.players[0].scores.map(obj => {
    return {
      hole: obj.hole,
      strokes: obj.shots
    }
  })
  for (var hole of match.course.scoreCard) {
    player.filter(obj => obj.hole === hole.hole)[0].par = hole.par
  }
  return player
}

function scoresFor9(scorecard, frontOrBack) {
  var data
  if (frontOrBack === 'front') {
    data = scorecard.slice(0,9)
  }else {
    data = scorecard.slice(9,18)
  }
  var totalPar = data.reduce((total, hole) => total + hole.par, 0)
  var totalStrokes = data.reduce((total, hole) => total + hole.strokes, 0)
  data.push({
    hole: frontOrBack.charAt(0).toUpperCase() + frontOrBack.slice(1),
    par: totalPar,
    strokes: totalStrokes,
    total: true
  })
  if ((frontOrBack === 'back' && scorecard.length > 9) || scorecard.length === 9) {
    data.push({
      hole: "Total",
      par: scorecard.reduce((total, hole) => total + hole.par, 0),
      strokes: scorecard.reduce((total, hole) => total + hole.strokes, 0),
      total: true
    })
  }else {
    if (frontOrBack === 'front') {
      data.push({
        hole: "",
        par: "",
        strokes: "",
        total: true
      })
    }
  }
  return data
}

</script>

<style>
.black-background {
  background: #363640
}

.fullscreen {
  height: 100vh
}

.distribute {
  display:flex;
  flex-direction:column;
  justify-content:space-around;
}

.white-text {
  color: white;
}

.circle-small{
    width:24px;
    height:24px;
    border: 1px solid #FFFFFF;
    border-radius:250px;
    font-size:15px;
    color:#FFFFFF;
    line-height:24px;
    text-align:center;
    margin: 2px;
}

.circle-big {
  width:30px;
  height:30px;
  border: 1px solid #FFFFFF;
  border-radius:250px;
  font-size:15px;
  color:#FFFFFF;
  line-height:30px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
  background-color: #ff4560;
}

.square-small{
    width:20px;
    height:20px;
    border: 1px solid #FFFFFF;
    border-radius:0px;
    font-size:15px;
    color:#FFFFFF;
    line-height:20px;
    text-align:center;
    margin: 4px;

}

.square-big {
  width:30px;
  height:30px;
  border: 1px solid #FFFFFF;
  border-radius:0px;
  font-size:15px;
  color:#FFFFFF;
  line-height:30px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
  background: #008ffb;
}

.no-border {
  width:30px;
  height:30px;
  font-size:15px;
  color:#FFFFFF;
  line-height:30px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
}

.fill-only {
  width:30px;
  height:30px;
  font-size:15px;
  color:#FFFFFF;
  line-height:30px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
}

.no-display {
  display: none
}

.very-small-text {
  font-size: 10px
}


.secondary-black-background {
  background-color: #393943;

}


</style>
