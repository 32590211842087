<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {

    }
  },
  async mounted () {
  }

}

</script>

<style>
#app {
  font-family: Urbanist, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #fafafa;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

h3 {
  color: rgb(5, 15, 25);
  font-size: 12pt;
  text-align: left;
  font-weight: 700
}

h5 {
  font-size: 14px;
}

.card-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px
}

.selector-button {
  width:100%;
  height: 100px;
  font-size: 16px;
  padding: 0px
}

.clickable {
  cursor: pointer;
}

.title-text {
  font-size: 60px;
  font-family: Helvetica, sans-serif;
  font-weight: 200
}

.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
}

.header-link, .header-link:visited {
  color:#2e2e2e;
}

.header-link:hover {
  color: #9e9e9e;
  text-decoration: none;
}

.header-link:focus{
  text-decoration: none;
  font-weight: bold
}

.navbar {
  padding:3px
}

.center-nav {
  justify-content: center
}

.nav-title {
  font-size: 10px;
  margin: 0px
}
.nav-fill {
  width:100%;
  margin-left: 20px;
  margin-right: 20px
}

.nav-max-width {
  max-width: 500px
}

.mt-6 {
  margin-top: 5rem !important
}

.card-bottom-border {
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left:20px;
  margin-right:20px;

}

.button-primary {
  background-color: #6dede1;
  border: 0px;
  width: 100%;
  font-weight: 700
}

.nav-icon, .nav-icon-text {

}

.form-label {
  color: grey;
  font-weight: 100;
  font-size: 10pt
}

.date-picker, .date-picker > button, .date-picker > label {
  border: 0px;
  width: 100%;
  font-weight: 100;
}

.small-text {
  font-size: 14px
}

.secondary-link {
  font-size: 14px;
  color: #9e9e9e;
  text-decoration: underline;
}

@font-face {
  font-family: 'Urbanist', sans-serif;
}
</style>
