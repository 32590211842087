<template>
  <div>
    <b-spinner class="mt-5" v-if="matchesLoading" type="grow" label="Spinning"></b-spinner>
    <b-card v-if="!matchesLoading">
    <b-list-group >
      <div class="card-title">
        <h3>Recent Cards</h3>
      </div>
      <b-card v-for="match in matches" class="card-bottom-border mb-2 clickable" v-bind:key="match.uuid" @click="matchSelected(match)">
        <b-row no-gutters>

          <b-col cols="11">
            <b-row align-h="center">
              <span>{{matchCourseTitle(match)}}</span>
            </b-row>
            <b-row align-h="center">
              <span style="font-weight: bold; display: block">{{playersTitle(match)}}</span>
            </b-row>
            <b-row align-h="center">
              <span>{{match.date}}</span>
            </b-row>
            <b-row align-h="center" v-if="!match.tee">
              <b-badge variant="warning">No tees selected</b-badge>
            </b-row>
            <b-row align-h="center" v-if="match.players[0].scores.filter(obj => obj.shots).length !== match.players[0].scores.length">
              <b-badge variant="danger">Incomplete</b-badge>
            </b-row>
          </b-col>
          <b-col cols="1">
            <i class="material-icons md-24 clickable mr-2" @click.stop="deleteMatch(match)">delete</i>
          </b-col>
        </b-row>
      </b-card>
    </b-list-group>
  </b-card>
  </div>
</template>
<script>
import MatchList from '../models/MatchList'
import CurrentUser from '../models/CurrentUser'
export default {
  name: 'MatchListView',
  props: ['user'],
  components: {
  },
  data() {
    return {
      matches: undefined,
      matchesLoading: undefined
    }
  },
  async mounted () {
    this.loadMatches()
  },
  watch: {

  },
  methods: {
    async loadMatches() {
      this.matchesLoading = true
      var matches = new MatchList()
      await matches.load()
      this.matches = matches.sort((a,b) => a.date < b.date? 1: -1)
      this.matchesLoading = false
    },
    async deleteMatch(match) {
      await match.delete()
      this.loadMatches()
    },
    matchCourseTitle(match) {
      if (match.course.location === match.course.courseName) return match.course.location
      return match.course.location? match.course.location + ": " + match.course.courseName : undefined
    },
    playersTitle(match) {
      if (!match.teams) {
        return match.players.map(player => player.firstName).join(" v ")
      }else {
        var teamANames = match.teams.teamA.players.map(key => {
          return match.players.filter(player => player.key === key)[0].firstName
        })

        var teamBNames = match.teams.teamB.players.map(key => {
          return match.players.filter(player => player.key === key)[0].firstName
        })
        return teamANames.join(" & ") + " v " + teamBNames.join(" & ")
      }
    },
    matchSelected(match) {
      this.$emit("matchSelected", match)
    }
  }
}

</script>

<style>
.align-left {
    text-align:left;
    color: #787878;

}

.match-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
}

.image-cropper {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

img {
  display: inline;
  margin: 0 auto;
  height: 50px;
  width: 50px;
}

</style>
