import {v4 as uuidv4} from 'uuid'
const minAveragePar = 0
const include9HoleRounds = true
const holesForHandicap = 3 * 18
export default class Golfer {
    constructor(player, isSignedInUser) {
      this.scores = []
      if (player) {
        Object.keys(player).forEach(key => this[key] = player[key])
        if (!this.key) this.key = uuidv4()
        this.isSignedInUser = isSignedInUser
        if (!this.handicap) this.handicap = isSignedInUser? getLocalHandicap() : 0
        if (!this.fullName) this.firstName + " " + this.surname
      }else {
        this.handicap = 0
      }

    }
    calculateHandicap(matches) {
        var differentials = this.getDifferentials(matches)
        var handicapTimeSeries = []
        while(differentials.length > 2) {
          var handicap = calculateHandicapFromDifferentials([...differentials].splice(0,20))
          if (handicap) {
            handicapTimeSeries.push({
              handicap: calculateHandicapFromDifferentials([...differentials].splice(0,20)),
              date: differentials[0].date
            })
          }
          differentials.shift()
        }
        if (handicapTimeSeries.length > 0) {
          var latestHandicap = handicapTimeSeries[0].scoreToPar / handicapTimeSeries[0].holes * 18
          this.handicapTimeSeries = handicapTimeSeries
          return latestHandicap.toFixed(0)
        }

        return 0
    }
    getDifferentials(matches) {
      var completedMatches = matches.sort((a,b) => new Date(a.date) < new Date(b.date)? 1: -1)
      var differentials = []
      completedMatches.forEach(match => {
        match.course.scoreCard = match.course.scoreCard.filter(hole => hole.hole !== 'Total')
        if (shouldUseMatch(match)) {
          var thisPlayer = match.players.filter(player => player.isSignedInUser)[0] || match.players[0] //Some old rounds don't mark the signed in player - should fix this
          var scoreToPar = totalScoreToParWithNoBlobs(thisPlayer.scores, match.course.scoreCard)
          var totalScore = totalScoreNoBlobs(thisPlayer.scores, match.course.scoreCard)
          var holeMultiplier = thisPlayer.scores.length / 18
          var differential = (totalScore - match.getCourseRating()) * ((113 * holeMultiplier) / match.getSlope())
          differentials.push({
            location: match.course.location, holes: match.course.scoreCard.length, scoreToPar: scoreToPar, date: match.date, differential: differential,
            totalScore: totalScore,
            holeMultiplier: holeMultiplier,
            rating: match.getCourseRating(),
            slope: match.getSlope(),
            par: match.getTotalPar()
          })
        }
      })
      var differentialsWithHoleAdjustments = differentials.map(diff => {
        diff.differential = diff.differential * (18 / diff.holes)
        return diff
      })
      return differentialsWithHoleAdjustments
    }
    save() {
      if (this.isSignedInUser) this.setHandicap(this.handicap)
    }
    setHandicap(handicap) {
      this.handicap = handicap
      if (this.isSignedInUser) {
        localStorage.handicap = handicap
      }
    }
    getPlayingHandicap() {
      return this.playingHandicap || this.handicap
    }
    setPlayingHandicap(match) {
      if (match.tee) {
        this.playingHandicap = Math.round(this.handicap * (match.getSlope() / 113))
      }else {
        this.playingHandicap = this.handicap
      }
      return this.playingHandicap
    }
    getTeam(match) {
      return match.teams.teamA.players.filter(key => key === this.key).length === 1? 'teamA': 'teamB'
    }
}

function calculateHandicapFromDifferentials(differentials) {
  differentials.sort((a,b) => a.differential - b.differential)
  var handicapCalculationRules = handicapCalculation[differentials.length]
  var best = [...differentials].splice(0,handicapCalculationRules.bestRoundsToUse)
  const average = best.reduce((total, diff) => total + diff.differential, 0) / best.length;
  return average - handicapCalculationRules.adjustment
}
var handicapCalculation = {
  3: {bestRoundsToUse: 1, adjustment: 2},
  4: {bestRoundsToUse: 1, adjustment: 1},
  5: {bestRoundsToUse: 1, adjustment: 0},
  6: {bestRoundsToUse: 2, adjustment: 1},
  7: {bestRoundsToUse: 2, adjustment: 0},
  8: {bestRoundsToUse: 2, adjustment: 0},
  9: {bestRoundsToUse: 3, adjustment: 0},
  10: {bestRoundsToUse: 3, adjustment: 0},
  11: {bestRoundsToUse: 3, adjustment: 0},
  12: {bestRoundsToUse: 4, adjustment: 0},
  13: {bestRoundsToUse: 4, adjustment: 0},
  14: {bestRoundsToUse: 4, adjustment: 0},
  15: {bestRoundsToUse: 5, adjustment: 0},
  16: {bestRoundsToUse: 5, adjustment: 0},
  17: {bestRoundsToUse: 6, adjustment: 0},
  18: {bestRoundsToUse: 6, adjustment: 0},
  19: {bestRoundsToUse: 7, adjustment: 0},
  20: {bestRoundsToUse: 8, adjustment: 0}
}
/*
var handicapCalculation = [
  {differentials: 3, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 4, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 5, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 6, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 7, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 8, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 9, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 10, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 11, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 12, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 13, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 14, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 15, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 16, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 17, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 18, bestRoundsToUse: 1, adjustment: 2},
  {differentials: 19, bestRoundsToUse: 1, adjustment: 2}
]
*/
function getLocalHandicap() {
  var handicap = localStorage.handicap
  if (!handicap) handicap = 0
  localStorage.handicap = handicap
  return handicap
}

function totalScoreToParWithNoBlobs(scores, holes) {
  var totalScore = totalScoreNoBlobs(scores, holes)
  var par = holes.reduce((total, hole) => total + hole.par, 0)
  return totalScore - par
}

function totalScoreNoBlobs(scores, holes) {
  var totalScore = scores.reduce((total, score) => {
    var par = holes.filter(hole => hole.hole === score.hole)[0].par
    var thisScore = score.shots
    if (thisScore - par > 2) {
      thisScore = par + 3 //Should be net double bogey
    }
    return total + thisScore
  }, 0)
  return totalScore
}

function shouldUseMatch(match) {
  match.checkScoresExist()
  //Course average par score is less than a set amount
  var averagePar = match.course.scoreCard.reduce((total, hole) => total += hole.par, 0) / match.course.scoreCard.length
  if (averagePar <= minAveragePar) return false

  if (!include9HoleRounds && match.course.scoreCard.length === 9) return false

  return match.players[0].scores && match.players[0].scores.filter(score => !score.shots).length === 0 //remove incomplete rounds
}
