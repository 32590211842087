import {v4 as uuidv4} from 'uuid'
import * as MatchesClient from '../clients/MatchesClient'
import Golfer from './Golfer'
import moment from 'moment'
export default class Match {
    constructor(players, course) {
      this.uuid = uuidv4()
      if (course) {
        this.scoreCard = course.scoreCard
      }else {
        this.course = {}
      }
      if (players) {
        this.players = players.map(player => new Golfer(player))
      }else {
        this.players = []
      }
      this.players.forEach(player => {
          player.scores = []
          this.course.scoreCard.forEach(hole => {
            player.scores.push({hole: hole.hole})
          })
      })
      this.synced = false
      this.date = moment().format("YYYY-MM-DD")
    }
    default(location, courseName, holes, slope, rating) {
      this.newCourse = true
      this.course.scoreCard = []
      this.course.location = location
      this.course.courseName = courseName

      //Add default holes
      for (var i = 0; i < holes; i++) {
        this.course.scoreCard.push({
          hole: i+1
        })
      }

      //Add slope and course rating
      this.course.tees = {}
      this.course.tees[this.tee] = {
        slope: 1 * slope || 113,
        rating: 1 * rating || holes * 4
      }
    }
    async save(localOnly, overrideSynced) {
      //TODO - check for unsynced localstorage version of the match before overwriting (am doing this already in MatchList but if that fails, this will overwrite)
      //Save to local storage, marked as unsynced unless explicity overwritten
      if (!overrideSynced) this.synced = false
      localStorage['match-' + this.uuid] = JSON.stringify(this)

      //Assuming this is to get rid of any 'total' entries in a player's scores
      this.players.forEach(player => {
        if (player.scores) {
          player.scores = player.scores.filter(score => score.hole > 0 && score.hole < 19)
        }
        var golfer = new Golfer(player)
        if (!player.playingHandicap) {
          golfer.setPlayingHandicap(this)
        }
          player.playingHandicap = golfer.playingHandicap
      })

      this.players = this.players.map(player => new Golfer(player))
      //Save to remote storage
      if (!localOnly) {
        try {
          await MatchesClient.saveMatch(this)
          this.synced = true
          localStorage['match-' + this.uuid] = JSON.stringify(this)
          //console.log("Match saved to remote storage")
        }catch(err) {
          console.error("Error saving match to remote storage")
        }
      }
    }
    getSlope() {
      if (this.course.scoreCard) {
        if(this.course.tees && this.tee) {
          if (this.course.scoreCard && this.course.tees[this.tee].slope / this.course.scoreCard.length > 8.62) {
            return this.course.tees[this.tee].slope * (this.course.scoreCard.length / 18)
          }else {
            return this.course.tees[this.tee].slope
          }
        }else {
          return (this.course.scoreCard.length / 18) * 113
        }
      }else {
        return 113
      }
    }
    getCourseRating() {
      if(this.course.tees) {
        if (this.course.scoreCard && this.course.tees[this.tee].rating / this.course.scoreCard.length > 4.5) {
          return this.course.tees[this.tee].rating * (this.course.scoreCard.length / 18)
        }else {
          return this.course.tees[this.tee].rating
        }
      }else {
        return this.getTotalPar()
      }
    }
    getTotalPar() {
      return this.course.scoreCard.reduce((total, hole) => total + hole.par, 0)
    }
    async delete() {
      await MatchesClient.deleteMatch(this.id)
      localStorage.removeItem('match-' + this.uuid)
    }
    async load(uuid) {
      if (uuid && uuid.indexOf("match-") > -1) uuid = uuid.split("match-")[1]
      if (uuid) this.uuid = uuid
      var data = localStorage['match-' + this.uuid]
      if (data) {
        data = JSON.parse(localStorage['match-' + this.uuid])
        Object.keys(data).forEach(key => this[key] = data[key])
      }else {
        //loadFromRemote
        data = await MatchesClient.getMatch(this.uuid)
        Object.keys(data).forEach(key => this[key] = data[key])
      }
      this.players = this.players.map(player => new Golfer(player))

    }
    async addAverages() {
      var matches = await MatchesClient.getMatches(this.course.location, this.course.courseName)
      matches = matches.filter(match => match.id !== this.id)
      this.players.forEach(player => {
        if (!player.scores) {
          player.scores = []
          this.course.scoreCard.forEach(hole => {
            player.scores.push({hole: hole.hole})
          })
        }
        player.scores.forEach(hole => {
          var holesForPlayer = allHolesForPlayer(matches, player.key)
          var previousScoresOnThisHole = holesForPlayer.filter(holeForPlayer => holeForPlayer && holeForPlayer.hole === hole.hole)
          var sum = previousScoresOnThisHole.reduce((total, score) => total + score.shots, 0)
          var average = sum / previousScoresOnThisHole.length
          hole.average = average
        })
      })
    }
    checkScoresExist() {
      this.players.forEach(player => {
        if ((!player.scores || player.scores.length === 0) && this.course && this.course.scoreCard) {
          player.scores = []
          this.course.scoreCard.forEach(hole => {
            player.scores.push({hole: hole.hole})
          })
        }
      })
    }
    playerByKey(key) {
      return this.players.filter(player => player.key === key)[0]
    }
}

function allHolesForPlayer(matches, playerKey) {
  var holes = []
  matches.forEach(match => {
    if (match.players.filter(player => player.key === playerKey)[0]) {
      holes = holes.concat(match.players.filter(player => player.key === playerKey)[0].scores)
    }
  })
  return holes
}
