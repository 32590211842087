const defaultRollingAverage = 72
export default class ShotsToPar {
  constructor(playerScores, rollingAverageAmount) {
    this.series = []
    this.labels = ["tribleBogeyPlus", "doubleBogey","bogey", "par","birdie", "eagle"]
    if (!rollingAverageAmount) rollingAverageAmount = defaultRollingAverage
    if (rollingAverageAmount > playerScores.length) rollingAverageAmount = playerScores.length / 3
    var sortedList = playerScores.concat().sort((a,b) => a.hole - b.hole).sort((a,b) => new Date(a.date) - new Date(b.date))
    for (var i = rollingAverageAmount; i < sortedList.length; i++) {
      var obj = sortedList.slice(i - rollingAverageAmount, i).reduce((total, scoreObj) => {
        var shotsToPar = scoreObj.shots - scoreObj.par
        if (!isNaN(shotsToPar)) {
        switch (shotsToPar) {
          case -2: total.eagle.count++; break;
          case -1: total.birdie.count++; break;
          case 0: total.par.count++; break;
          case 1: total.bogey.count++; break;
          case 2: total.doubleBogey.count++; break;
          default: total.tribleBogeyPlus.count++
        }
      }
        return total
      }, {par:{count:0}, bogey:{count:0}, birdie:{count:0}, eagle: {count:0}, doubleBogey: {count:0}, tribleBogeyPlus: {count:0}})

      Object.keys(obj).forEach(key => {
        obj[key].percent = obj[key].count / rollingAverageAmount
      })
      this.series.push(obj)
    }
  }
}
