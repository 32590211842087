<template>
      <b-table :items="tableData" :fields="fields" thead-class='very-small-text' table-class='white-text black-table'>
        <template v-slot:cell()="data" >
          <div v-if="data.item.type === 'strokes'">
            <div :class="getStrokesClass(data.field.key, data.value, 'big')">
              <div :class="getStrokesClass(data.field.key, data.value, 'small') + ' ' + getTotalClass(data)">
                {{data.value}}
              </div>
            </div>
          </div>
          <div v-if="data.item.type === 'par'">
            {{data.value}}
          </div>
        </template>
      </b-table>
</template>

<script>
import CurrentUser from '../models/CurrentUser'
import Match from '../models/Match'
export default {
  name: 'ScoreCardFullScreen',
  components: {

  },
  props: ['items'],
  data() {
    return {
      tableData: undefined,
      fields: undefined
    }
  },
  methods: {
    getCourseName() {
      if (this.match.course.location === this.match.course.courseName) return this.match.course.location
      return `${this.match.course.location}: ${this.match.course.courseName}`
    },
    getTotalClass(scoreObj) {
      if (scoreObj.item.total) {
        if (scoreObj.item.hole === "Total") {
          return 'bold-large-text'
        }else {
          return 'bold'
        }
      }
    },

    getStrokesClass(hole, strokes, divSize) {
      if (isNaN(hole * 1))return 'white-text'
      var scoreObj = {
        par: this.items.filter(obj => obj.hole === hole * 1)[0].par,
        strokes: strokes
      }
      var shotsToPar = scoreObj.par - scoreObj.strokes
      if(shotsToPar === 0) {
        if (divSize === 'big') return 'fill-only'
      }

      if (shotsToPar >= 1) {
        if (divSize === 'big') return 'circle-big'
      }

      if(shotsToPar >= 2) {
        if (divSize === 'small') return 'circle-small'
      }

      if(shotsToPar <= -1) {
        if (divSize === 'big') return 'square-big'
      }

      if(shotsToPar <= -2) {
        if (divSize === 'small') return 'square-small'
      }
    }
  },
  async mounted () {
    this.tableData = transposeData(this.items)
    this.fields = createFields(this.items)
  }
}

function getTDClass(value, key, item) {
  if (key === "Total") {
    return 'less-cell-padding total-row'
  }
  return 'less-cell-padding'
}

function transposeData(data) {
  var tableData = [{},{}]
  for (var obj of data) {
    if (obj.hole !== "Total"){
      tableData[0][obj.hole] = obj.par
      tableData[1][obj.hole] = obj.strokes
      tableData[0].type="par"
      tableData[1].type="strokes"
    }

  }
  return tableData
}

function createFields(data) {
  var fields = []
  for (var obj of data) {
    if (obj.hole !== "Total") {
      fields.push({
        key: obj.hole + "",
        tdClass: getTDClass
      })
    }
  }
  return fields
}
</script>

<style>
.black-background {
  background: #363640
}

.fullscreen {
  height: 100vh
}

.distribute {
  display:flex;
  flex-direction:column;
  justify-content:space-around;
}

.white-text {
  color: white;
}

.circle-small{
    width:25px;
    height:25px;
    border: 1px solid #FFFFFF;
    border-radius:250px;
    font-size:15px;
    color:#FFFFFF;
    line-height:25px;
    text-align:center;
}

.circle-big {
  width:50px;
  height:50px;
  border: 1px solid #FFFFFF;
  border-radius:250px;
  font-size:15px;
  color:#FFFFFF;
  line-height:50px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
}

.square-small{
    width:20px;
    height:20px;
    border: 1px solid #FFFFFF;
    border-radius:0px;
    font-size:15px;
    color:#FFFFFF;
    line-height:20px;
    text-align:center;
    margin: 4px
}

.square-big {
  width:30px;
  height:30px;
  border: 1px solid #FFFFFF;
  border-radius:0px;
  font-size:15px;
  color:#FFFFFF;
  line-height:30px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
}

.no-border {
  width:30px;
  height:30px;
  font-size:15px;
  color:#FFFFFF;
  line-height:30px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
}

.no-display {
  display: none
}

.very-small-text {
  font-size: 10px
}

.less-cell-padding {
  padding: 2px !important;
}

.total-row {
  font-weight: 700;
  border-top: 1px solid #737577 !important;
  font-size: 20px
}

</style>
