<template>
  <div class="mt-2" v-if="loaded" >
    <b-row class="justify-content-md-center">
      <b-col cols="12" lg="4">
      <b-card class="mb-2" border-variant="light" >
        <b-row >
          <b-col cols="12">
            <b-row align-h="center">
              <div class="image-cropper">
                <img :src="user.image" />
              </div>
            </b-row>
            <b-row class="mt-2" align-h="center">
              <h3>{{user.firstName}} {{user.surname}}</h3>
            </b-row>
            <b-row class="" align-h="center">
              <p >Unique ID:</p>
              <div class="ml-2">
                <b-badge pill variant="dark">{{user.key}}</b-badge>
              </div>
            </b-row>
            <b-row class="mt-3 mr-3 ml-3" align-h="center" v-if="false">
              <p class="small-text-black">All the information you see above apart from your unique ID is known by Google and not stored by any of our systems.</p>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    </b-row>
  </div>
</template>
<script>
import CurrentUser from '../models/CurrentUser'
export default {
  name: 'ProfilePage',
  components: {
  },
  props: [],
  data() {
    return {
      user: undefined,
      loaded: false
    }
  },
  async mounted () {
    this.loaded = false
    this.user = new CurrentUser()
    await this.user.load()
    this.loaded=true
  },
  watch: {
  },
  methods: {

  }
}

</script>

<style>
.image-cropper {
  width: 75px;
  height: 75px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

img {
  display: inline;
  margin: 0 auto;
  height: 75px;
  width: 75px;
}

.small-text-black {
  font-size: 12px;
  margin: 0px
}

</style>
