<template>
  <div>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
    <b-navbar class="center-nav nav-black" type="dark" variant="dark" fixed="top" ref="top">
        <b-navbar-nav fill align="center" class="nav-max-width"> <!--class=""nav-fill-->
          <b-nav-item >
            <img class="p-2 nav-logo" src="@/assets/sgc_logo.png" />
          </b-nav-item>
        </b-navbar-nav>
    </b-navbar>
    <div class="background pt-10">
      <b-container class="h-100">
        <b-row class="h-100">
          <b-col cols="12">
            <div class="h-100 d-flex flex-column">
              <p class="title-text">Shoreditch Golf Club</p>
              <b-row align-h="center">
                <b-col cols="auto" >
                  <b-button squared class="mt-5 join-button vertical-center" :to="{ name: 'Home'}">JOIN</b-button>
                </b-col>
              </b-row>
              <b-row align-v="end"  class="h-100 justify-content-center pb-5">
                  <i class="icon-large material-icons white clickable" @click="scrollToElement">expand_more</i>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div >
      <b-container class="margin-large container-large" ref="infoContainer">
        <b-row>
          <img class="p-2 content-logo" src="@/assets/sgc_logo_green.png" />
        </b-row>
        <b-row class="mt-5">
          <b-col lg="5" cols="12" >
              <h3 class="info-heading mb-3">A new type of golf club</h3>
              <p class="info-text">Shoreditch Golf Club gives you the chance to be a member of a golf club without the old fashioned stuffiness, the tie-in to a single or course or the huge fees. We’ll store your cards, calculate your handicap and give you exclusive access to regular competitions.
              </p>
          </b-col>
          <b-col lg="2" class="d-none d-lg-block" />
          <b-col lg="5" cols="12">
            <hr class="d-xl-none mt-5 mb-5">
            <h3 class="info-heading mb-3">Know your handicap</h3>
            <p class="info-text">Using our user friendly online portal you can store all your cards and we will calculate what your handicap would be. Track your progress over time and get insights into where you can bring it down.
            </p>
          </b-col>
        </b-row>
        <hr class="mt-5 mb-5">
        <b-row class="mt-5">
          <b-col lg="5" cols="12" >
              <h3 class="info-heading mb-3">Be part of a major</h3>
              <p class="info-text">Shoreditch Golf Club hosts regular, online-first competitions. Your SGC handicap means you have a higher chance of becoming one of our top golfers in our varied format competitions. 4 times a year we hold open Major tournaments over 2 days for the ultimate bragging rights.
              </p>
          </b-col>
          <b-col lg="2" class="d-none d-lg-block" />
          <b-col lg="5" cols="12">
            <hr class="d-xl-none mt-5 mb-5">
            <h3 class="info-heading mb-3">Membership you can afford</h3>
            <p class="info-text">Joining Shoreditch Golf Club is completely free. Once joined you can become a premium member to get access to full functionality including competitions, verified cards and handicaps. Our premium membership comes at a miniscule fraction of the cost of a mainstream golf membership.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FrontPage',
  components: {
  },
  props: [],
  data() {
    return {

    }
  },
  mounted () {
    const el = this.$refs.top;

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'smooth'});
    }
  },
  watch: {

  },
  methods: {
    scrollToElement() {
    const el = this.$refs.infoContainer;

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'smooth'});
    }
  }

  }
}
</script>

<style>

.nav-black {
  background-color: black !important;
  color: black;
}

.background {
  background-image: url('../assets/background_image.jpg');
  height: 100vh;
  background-size: 150vh;
}

.pt-10 {
  padding-top: 180px;
}

.title-text {
  text-align: center;
  color: white;
  font-weight: 600;
  width:100%;
  font-family: Urbanist, sans-serif;
}

.join-button:hover {
  font-weight: 900;
  color: #363636;
  background-color: #FFFFFF00;
  border-color: #00000000;
}

.join-button, .join-button:focus, .join-button:active, .join-button:visited {
  background-color: #00000000;
  color: #FFFFFF;
  border-color: #00000000;
  height: 70px;
  min-width: 200px;
  font-weight: 500;
}

@media screen and (min-width: 601px) {
  .join-button, .join-button:focus, .join-button:active {
    font-size: 40px;
  }
  .join-button:hover, .join-button:visited{
    font-size: 41px;
  }
  .title-text {
    font-size: 100px;
  }
  .icon-large {
    font-size:80px !important;
  }

  .info-text {
    font-size: 20px;
  }
  .info-heading {
    font-size: 30px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .join-button, .join-button:focus, .join-button:active {
    font-size: 20px;
  }
  .join-button:hover, .join-button:visited{
    font-size: 21px;
  }
  .title-text {
    font-size: 50px;
  }
  .icon-large {
    font-size:40px !important;
  }

  .info-text {
    font-size: 16px;
  }
  .info-heading {
    font-size: 20px;
  }
}

.fill-row {
  height:100%
}

.white {
  color: white
}

.nav-logo {
  display: block;
  max-width:100px;
  max-height:70px;
  width: auto;
  height: auto;
}

.content-logo {
  display: block;
  max-width:300px;
  max-height:120px;
  width: auto;
  height: auto;
}

.info-text {
  font-family: "freight-sans-pro", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #363636;
}

.info-heading {
  font-family: "freight-sans-pro", Helvetica, Arial, sans-serif;
  text-align: center;
  font-weight: 600;
  color: #363636;
}


.margin-large {
  padding-top: 150px;
}

.container-large {
  max-width: 90%;
  margin-bottom: 100px
}



</style>
