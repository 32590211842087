import moment from 'moment'
export default class GreensInRegulation {
  constructor(playerScores) {
    this.playerScores = playerScores.filter(score => score.shots? true: false)
    var girMap = this.playerScores.reduce((dateObj, score) => {
        if (!dateObj[score.date]) dateObj[score.date] = { gir: 0, count: 0 }
        dateObj[score.date].count++
        if (isGIR(score)) dateObj[score.date].gir++
      return dateObj
    }, {})

    var girList = Object.keys(girMap).map(dateKey => {
      girMap[dateKey].date = dateKey
      girMap[dateKey].percentage = (girMap[dateKey].gir / girMap[dateKey].count * 100).toFixed(0) * 1
      return girMap[dateKey]
    })
    this.series = girList.sort((a,b) => new Date(a.date) > new Date(b.date)? 1: -1)
    this.dates = this.series.map(obj => moment(obj.date).format("DD-MMM-YY"))
  }
  last(amount) {
      var arr = this.playerScores.slice(0,amount)
      var girs = arr.reduce((total, val) => {
        return total + (isGIR(val)? 1:0)
      }, 0)
      return (girs / amount * 100).toFixed(0) * 1
  }
}


function isGIR(score) {
  return (score.par - 2) >= (score.shots - score.putts)
}
